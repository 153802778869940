import React from 'react'
import { Button } from 'semantic-ui-react'

import './contact-me.scss'

const ContactMe: React.FunctionComponent = (): JSX.Element => {
	return (
		<Button
			className={'contact-me'}
			as={'a'}
			primary
			size="small"
			icon="mail"
			href={'mailto:jivert.mmt-b2015@fh-salzburg.ac.at'}
			content="Kontakt"
		/>
	)
}

export default ContactMe
