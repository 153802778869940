/* eslint-disable @typescript-eslint/camelcase */
import * as firebase from 'firebase'
import 'firebase/firestore'
import { firebaseConfig } from '../config'
import { getUserAgent, getVpSizes } from './checkDesktop'

class FirebaseClient {
	constructor() {
		this.fire = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app()
		this.db = this.fire.firestore()
	}

	async getSingle(collectionName, id) {
		const { db } = this
		try {
			return await db.collection(collectionName).doc(id)
		} catch (err) {
			throw new Error(err)
		}
	}

	async getAll(collectionName) {
		const { db } = this
		try {
			const snapshot = await db.collection(collectionName).get()
			return snapshot.empty && snapshot.docs
		} catch (err) {
			throw new Error(err)
		}
	}

	async getLatest(collectionName) {
		const { db } = this
		try {
			const snapshot = await db
				.collection(collectionName)
				.orderBy('added_at', 'desc')
				.limit(1)
				.get()
			return !snapshot.empty && snapshot.docs[0].ref
		} catch (err) {
			throw new Error(err)
		}
	}

	async getLengthOfCollection(collectionName) {
		const docRefs = await this.getAll(collectionName)
		return docRefs ? docRefs.length : 0
	}

	async getLengthOfReferencesToOtherCollection(collectionName, referenceField, reference) {
		const { db } = this
		try {
			const refs = await db
				.collection(collectionName)
				.where(referenceField, '==', reference)
				.get()
			return refs.size
		} catch (err) {
			throw new Error(err)
		}
	}

	async create(collectionName, attributes) {
		const { db } = this
		try {
			return await db.collection(collectionName).add({
				added_at: Date.now(),
				...attributes
			})
		} catch (err) {
			throw new Error(err)
		}
	}

	async updateMerge(collectionName, id, attributes) {
		const { db } = this
		try {
			return await db
				.collection(collectionName)
				.doc(id)
				.set({ ...attributes }, { merge: true })
		} catch (err) {
			throw new Error(err)
		}
	}
}

class FirebaseClientSerie extends FirebaseClient {
	async getSingle(id) {
		return super.getSingle('serie', id)
	}

	async create(attributes = {}) {
		return await super.create('serie', attributes)
	}

	async getLatest() {
		return await super.getLatest('serie')
	}

	async checkIfSerieIsBelowMaxNumberOfProbanden() {
		const lastSerie = await this.getLatest()
		if (!lastSerie) return false
		const lengthOfLastSerie = await this.getLengthOfProbandenInSerie()
		return lengthOfLastSerie && lengthOfLastSerie <= 3
	}

	async getLengthOfProbandenInSerie(serieRef = null) {
		const lastSerie = serieRef || (await this.getLatest())
		return await this.getLengthOfReferencesToOtherCollection('proband', 'serie_id', lastSerie)
	}
}

class FirebaseClientProband extends FirebaseClient {
	async getSingle(id) {
		return super.getSingle('proband', id)
	}

	async create(attributes = {}) {
		return super.create('proband', attributes)
	}

	async updateMerge(id, attributes) {
		return super.updateMerge('proband', id, attributes)
	}
}

class FirebaseClientDaten extends FirebaseClient {
	async create(attributes = {}) {
		return super.create('daten', attributes)
	}
}

export const createNewProbandEntryForSerie = async attributes => {
	const clientSerie = new FirebaseClientSerie()
	const clientProband = new FirebaseClientProband()
	const serie_id = (await clientSerie.checkIfSerieIsBelowMaxNumberOfProbanden())
		? await clientSerie.getLatest()
		: await clientSerie.create()
	const vpSettings = { userAgent: getUserAgent(), ...getVpSizes() }
	const probandRef = await clientProband.create({ serie_id, ...vpSettings, ...attributes })
	return { serieRef: serie_id, probandRef }
}

export const createNewDataEntryForProband = async (measuredTime, sequenceIdx, probandRef) => {
	const clientDaten = new FirebaseClientDaten()

	return await clientDaten.create({ measured_time: measuredTime, sequence_idx: sequenceIdx, proband_id: probandRef })
}

export const getProbandenNumberInLastSerie = async serieRef => {
	const clientSerie = new FirebaseClientSerie()
	return await clientSerie.getLengthOfProbandenInSerie(serieRef)
}

export const getRefForProbandId = async probandId => {
	const clientProband = new FirebaseClientProband()
	return await clientProband.getSingle(probandId)
}

export const getRefForSerieId = async serieId => {
	const clientSerie = new FirebaseClientSerie()
	return await clientSerie.getSingle(serieId)
}

export const updateProbandEntry = async (id, attributes) => {
	const clientProband = new FirebaseClientProband()
	return await clientProband.updateMerge(id, attributes)
}
