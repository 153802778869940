interface IClassList {
	baseClass?: string
	modifiers?: string[]
	elements?: IElementModifiersList[]
}

export interface IElementModifiersList {
	element: string
	elementModifier?: string
}

const generateModifierList = (element: string, modifiers: string[] = []): string => {
	return `${modifiers
		.map((modifier: string): string | null => (modifier.length > 0 ? `${element}--${modifier}` : null))
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		.filter((val: any): boolean => Boolean(val))
		.join(' ')}`
}
// Keep base class empty for only elements mode
const generateClassList = ({ baseClass = '', modifiers = [], elements = [] }: IClassList): string =>
	`${(baseClass.length && `${baseClass} ${generateModifierList(baseClass, modifiers)}`) || ''} ${elements.map(
		({ element, elementModifier }: IElementModifiersList): string => {
			return `${baseClass}__${element} ${(elementModifier &&
				`${baseClass}__${generateModifierList(element, (elementModifier && [elementModifier]) || [])}`) ||
				''}`
		}
	)}`

export default generateClassList
