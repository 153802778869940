import React from 'react'
import { IBasePattern } from '../../utils/BasePatterns'
import generateClasslist from '../../utils/generateClasslist'

import './image.scss'

export interface IImageProps extends IBasePattern {
	src: string
	alt: string
	srcSet?: string
	type?: string
	imageMeta?: string
}

interface IImageState {
	image: string | undefined
}

interface ModuleType {
	default: string
}

const BASE_CLASS = 'a-image'

class Image extends React.Component<IImageProps, IImageState> {
	public state = {
		image: undefined
	}

	public async componentDidMount(): Promise<void> {
		try {
			const image: string = (await this.getImageFromPath()).default
			this.setState({ image })
		} catch (err) {
			throw new Error(`Image component; ${err}`)
		}
	}

	public render(): JSX.Element {
		const { modifiers, alt, srcSet, type, imageMeta } = this.props
		const { image } = this.state

		return (
			<div
				className={generateClasslist({
					baseClass: BASE_CLASS,
					modifiers,
					elements: (type && [{ element: type }]) || []
				})}
			>
				{image && <img src={image} alt={alt} srcSet={srcSet} />}
				{image && imageMeta && <span className={'a-image__meta'}>{imageMeta}</span>}
			</div>
		)
	}

	private async getImageFromPath(): Promise<ModuleType> {
		const { src } = this.props
		return src && src !== '' && import(/* webpackMode: "eager" */ `../../../public/assets/images/${src}`)
	}
}

export default Image
