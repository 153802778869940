import React from 'react'
import { Form } from 'semantic-ui-react'
import Text from '../../atoms/Text/Text'

interface IPreQuestionnaireProps {
	onSubmit: (questionnaireData: IPreQuestionnaireState) => Promise<void>
	loading: boolean
}

export interface IPreQuestionnaireState {
	age: string
	gender: string
	attitude: string
}

const genderOptions = [
	{ key: 'm', text: 'Männlich', value: 'male' },
	{ key: 'f', text: 'Weiblich', value: 'female' },
	{ key: 'o', text: 'Anderes Geschlecht', value: 'other' }
]
const attitudeOptions = [
	{
		key: 'p',
		text:
			'Positiv - Sie empfinden Werbung als Bereicherung und inspirierend, zum Beispiel um über Produkte informiert zu bleiben.',
		value: 'positive'
	},
	{
		key: 'e',
		text:
			'Gleichgültig - Sie empfinden Werbung weder als positiv noch negativ und können diese gegebenfalls ausblenden.',
		value: 'even'
	},
	{ key: 'n', text: 'Negativ - Sie empfinden Werbung als störend und unattraktiv.', value: 'negative' }
]

class PreQuestionnaire extends React.Component<IPreQuestionnaireProps, IPreQuestionnaireState> {
	public state = {
		age: '',
		gender: '',
		attitude: ''
	}
	// @ts-ignore
	public handlePreQuestionnaireInputChange = (e, { name, value }): void =>
		// @ts-ignore
		this.setState(prevState => ({
			...prevState,
			[name]: value
		}))

	public handlePreQuestionnaireSubmit = (): void => {
		const { onSubmit } = this.props

		onSubmit({ ...this.state })
	}

	public render() {
		const { loading } = this.props
		return (
			<Form onSubmit={this.handlePreQuestionnaireSubmit.bind(this)}>
				<Form.Group widths="equal">
					<Form.Input
						required
						fluid
						label="Alter"
						placeholder="Alter"
						type="number"
						name="age"
						// @ts-ignore
						onChange={this.handlePreQuestionnaireInputChange}
					/>
					<Form.Select
						required
						fluid
						label="Geschlecht"
						options={genderOptions}
						placeholder="Geschlecht"
						name="gender"
						// @ts-ignore
						onChange={this.handlePreQuestionnaireInputChange}
					/>
				</Form.Group>
				<Form.Select
					required
					fluid
					label="Haltung gegenüber Werbung"
					options={attitudeOptions}
					placeholder="Wie ist Ihre generelle Haltung gegenüber Werbung auf Nachrichtenportalen?"
					name="attitude"
					// @ts-ignore
					onChange={this.handlePreQuestionnaireInputChange}
				/>
				<Form.Button positive loading={loading}>
					Weiter
				</Form.Button>
			</Form>
		)
	}
}

export default PreQuestionnaire
