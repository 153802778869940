import path from 'path'

const appDirectory = '.'
const resolveApp = relativePath => path.resolve(appDirectory, relativePath)

export const config = {
	paths: {
		root: resolveApp('.'),
		src: resolveApp('src'),
		atoms: resolveApp('src/atoms'),
		molecules: resolveApp('src/molecules'),
		organisms: resolveApp('src/organisms')
	}
}

export const firebaseConfig = {
	apiKey: 'AIzaSyBXjPelNg0iFdx90vatcrscnwicNT03SLc',
	authDomain: 'bakk2-e6c6c.firebaseapp.com',
	databaseURL: 'https://bakk2-e6c6c.firebaseio.com',
	projectId: 'bakk2-e6c6c',
	storageBucket: 'bakk2-e6c6c.appspot.com',
	messagingSenderId: '701796512493',
	appId: '1:701796512493:web:335639106417ae52'
}
