import React from 'react'

import './modal.scss'
import { IBasePattern } from '../../utils/BasePatterns'
import { Modal as SemanticModal } from 'semantic-ui-react'
import Image from '../../atoms/Image/Image'

export interface IModalProps extends IBasePattern {
	ad?: boolean
	headerText?: string
	children?: JSX.Element
	onClose?: () => void
}

// const BASE_CLASS = 'm-modal'

const Modal: React.FunctionComponent<IModalProps> = ({
	children,
	ad,
	headerText,
	onClose,
	modifiers
}: IModalProps): JSX.Element => {
	if (ad) {
		return (
			<SemanticModal
				style={{ width: 'auto' }}
				defaultOpen
				closeOnDimmerClick={false}
				closeIcon={true}
				centered
				onClose={onClose}
			>
				<Image
					alt={''}
					src={`ads/rectangle/ad${Math.floor(Math.random() * 5)}.png`}
					modifiers={['pop-up', 'ad']}
				/>
			</SemanticModal>
		)
	}
	return (
		<SemanticModal defaultOpen closeOnDimmerClick={false}>
			<SemanticModal.Header>{headerText}</SemanticModal.Header>
			<SemanticModal.Content>
				<SemanticModal.Description>{children}</SemanticModal.Description>
			</SemanticModal.Content>
		</SemanticModal>
	)
}

export default Modal
