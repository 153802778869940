import React, { createRef } from 'react'
import { checkIfDesktop, checkIfLandscapeIsPossible } from '../utils/checkDesktop'
import Modal from '../organisms/Modal/Modal'
import Text from '../atoms/Text/Text'
import {
	createNewDataEntryForProband,
	createNewProbandEntryForSerie,
	getProbandenNumberInLastSerie,
	getRefForProbandId,
	getRefForSerieId,
	updateProbandEntry
} from '../utils/firebaseClient'
import sequences from './_data/sequences.json'
import { Dimmer, Loader, Button as SemanticButton, Input, Ref, Button } from 'semantic-ui-react'
import moment, { Moment } from 'moment'
import PreQuestionnaire, { IPreQuestionnaireState } from '../templates/PreQuestionnaireTemplate/PreQuestionnaire'
import PostQuestionnaire, { IPostQuestionnaireState } from '../templates/PostQuestionnaireTemplate/PostQuestionnaire'

interface ISequence {
	template: string
	adAmount: string
	instruction: string
}

interface INewsPageState {
	validScreenSize: boolean
	landScapePossible: boolean
	disclaimerDone: boolean
	preQuestionnaireDone: boolean
	started: boolean
	siteStarted: boolean
	probandRef: object | null
	serieRef: object | null
	probandNr: number | null
	loading: boolean
	sequenceIdx: number
	tagLoaded: boolean
	siteDone: boolean
	studyCompleted: boolean
	startTime: Moment | null
	endTime: Moment | null
	completed: boolean
	postQuestionnaireDone: boolean
}

class NewsPage extends React.Component<{}, INewsPageState> {
	public state = {
		validScreenSize: false,
		landScapePossible: false,
		disclaimerDone: false,
		preQuestionnaireDone: false,
		started: false,
		siteStarted: false,
		probandRef: null,
		serieRef: null,
		probandNr: null,
		loading: false,
		sequenceIdx: 0,
		tagLoaded: false,
		siteDone: false,
		studyCompleted: false,
		startTime: null,
		endTime: null,
		completed: false,
		postQuestionnaireDone: false
	}
	private dynamicTag: JSX.Element | undefined
	private inputRef = createRef()

	public async componentDidMount(): Promise<void> {
		await this.hydrateStateWithLocalStorage()
		this.setState(prevState => ({
			...prevState,
			landScapePossible: this.isLandscapePossible(),
			validScreenSize: this.hasValidScreenSize()
		}))

		// add event listener to save state to localStorage
		// when user leaves/refreshes the page
		window.addEventListener('beforeunload', this.saveStateToLocalStorage.bind(this))
		window.addEventListener('orientationchange', this.reValidateScreenSize)
		window.addEventListener('resize', this.reValidateScreenSize)
	}

	public componentWillUnmount(): void {
		window.removeEventListener('beforeunload', this.saveStateToLocalStorage.bind(this))

		// saves if component has a chance to unmount
		this.saveStateToLocalStorage()
	}

	public onDisclaimerDone = (): void => {
		this.setState(prevState => ({ ...prevState, disclaimerDone: true }))
	}

	public onPreQuestionnaireSend = async (preQuestionnaireData: IPreQuestionnaireState): Promise<void> => {
		this.setState(prevState => ({ ...prevState, loading: true }))
		const { serieRef, probandRef } = await createNewProbandEntryForSerie(preQuestionnaireData)
		// @ts-ignore
		this.setState(prevState => ({ ...prevState, serieRef, probandRef, loading: false, preQuestionnaireDone: true }))
	}

	public onStart = async (): Promise<void> => {
		this.setState(
			prevState => ({ ...prevState, loading: true, started: true }),
			async (): Promise<void> => {
				await this.probandNummer()
				this.setState(prevState => ({ ...prevState, loading: false }))
			}
		)
	}

	public onSiteStart = async (): Promise<void> => {
		this.setState(prevState => ({ ...prevState, siteStarted: true, loading: true }))
		await this.importDynamicTag()
	}

	public onSiteDone = (): void => {
		this.setState(prevState => ({ ...prevState, siteDone: true }))
	}

	public onSiteDoneSend = async (): Promise<void> => {
		this.setState(
			prevState => ({ ...prevState, loading: true, endTime: moment() }),
			async (): Promise<void> => {
				await this.sendDataToFirebase()
				this.checkIfSequenceCompleted()
			}
		)
	}

	public onStudyCompletedFurther = (): void => {
		this.setState(prevState => ({ ...prevState, completed: true }))
	}

	public onPostQuestionnaireSubmit = async (postQuestionnaireData: IPostQuestionnaireState): Promise<void> => {
		const { probandRef } = this.state
		this.setState(prevState => ({ ...prevState, loading: true }))
		// @ts-ignore
		await updateProbandEntry(probandRef.id, postQuestionnaireData)
		this.setState(prevState => ({ ...prevState, loading: false, postQuestionnaireDone: true }))
	}

	public render(): JSX.Element {
		const {
			validScreenSize,
			disclaimerDone,
			preQuestionnaireDone,
			started,
			siteStarted,
			loading,
			siteDone,
			studyCompleted,
			completed,
			postQuestionnaireDone
		} = this.state

		if (!validScreenSize) return this.renderErrorModalIfNotOnDesktop()

		if (!disclaimerDone) return this.renderDisclaimer()

		if (!preQuestionnaireDone) return this.renderPreQuestionnaireModal()

		if (!started) return this.renderStartModal()

		if (postQuestionnaireDone) return this.renderPostQuestionnaireDoneModal()

		if (completed) return this.renderPostQuestionnaireModal()

		if (studyCompleted) return this.renderCompletedModal()

		if (!siteStarted) return this.renderSiteStartModal()

		if (siteDone) return this.renderSiteDoneModal()

		if (loading) {
			return (
				<Dimmer active>
					<Loader size="massive">Loading</Loader>
				</Dimmer>
			)
		}

		return this.renderDynamicTemplate()
	}

	private async hydrateStateWithLocalStorage(): Promise<void> {
		this.setState(prevState => ({ ...prevState, loading: true }))
		// for all items in state
		for (let key in this.state) {
			// if the key exists in localStorage
			if (localStorage.hasOwnProperty(key)) {
				// get the key's value from localStorage
				let value = localStorage.getItem(key)

				// parse the localStorage string and setState
				try {
					if (key === 'probandRef') {
						// @ts-ignore
						const probandRef = await getRefForProbandId(JSON.parse(value))
						// @ts-ignore
						value = probandRef
					} else if (key === 'serieRef') {
						// @ts-ignore
						const serieRef = await getRefForSerieId(JSON.parse(value))
						// @ts-ignore
						value = serieRef
					} else {
						value = value && JSON.parse(value)
					}
					// @ts-ignore
					this.setState({ [key]: value })
				} catch (e) {
					// handle empty string
					// @ts-ignore
					this.setState({ [key]: value })
				}
			}
		}
		this.setState(prevState => ({ ...prevState, loading: false }))
	}

	private saveStateToLocalStorage(): void {
		// for every item in React state
		for (let key in this.state) {
			switch (key) {
				case 'startTime':
				case 'endTime':
				case 'siteStarted':
				case 'siteDone':
					break
				case 'probandRef':
				case 'serieRef':
					localStorage.setItem(
						key,
						// @ts-ignore
						JSON.stringify((this.state[key] && this.state[key].id && this.state[key].id) || this.state[key])
					)
					break
				default:
					// @ts-ignore
					localStorage.setItem(key, JSON.stringify(this.state[key]))
					break
			}
		}
	}

	private hasValidScreenSize = (): boolean => checkIfDesktop()

	private isLandscapePossible = (): boolean => checkIfLandscapeIsPossible()

	private reValidateScreenSize = (): void => {
		this.setState(prevState => ({
			...prevState,
			landScapePossible: this.isLandscapePossible(),
			validScreenSize: this.hasValidScreenSize()
		}))
	}

	private async probandNummer(): Promise<void> {
		const { serieRef } = this.state
		const probandNr = await getProbandenNumberInLastSerie(serieRef)
		this.setState(prevState => ({ ...prevState, probandNr }))
	}

	private async importDynamicTag(): Promise<void> {
		const { probandNr, sequenceIdx } = this.state
		const data: ISequence | null = probandNr && (sequences.runs[probandNr][sequenceIdx] as ISequence)
		// @ts-ignore
		const Tag = data && data.template
		// @ts-ignore
		this.dynamicTag = this.dynamicTag || (await import(`../templates/${Tag}/${Tag}`)).default
		this.setState(prevState => ({ ...prevState, tagLoaded: true, loading: false, startTime: moment() }))
	}

	private async sendDataToFirebase(): Promise<void> {
		const { probandRef, sequenceIdx, startTime, endTime } = this.state
		// @ts-ignore
		await createNewDataEntryForProband(endTime.diff(startTime, 'seconds'), sequenceIdx, probandRef)
		this.setState(prevState => ({
			...prevState,
			loading: false,
			siteDone: false,
			siteStarted: false,
			tagLoaded: false,
			sequenceIdx: this.state.sequenceIdx + 1,
			startTime: null,
			endTime: null
		}))
	}

	private checkIfSequenceCompleted(): void {
		const { sequenceIdx } = this.state
		const studyCompleted = sequenceIdx > 3
		this.setState(prevState => ({ ...prevState, studyCompleted }))
	}

	private renderDisclaimer(): JSX.Element {
		return (
			<Modal headerText="Start">
				<>
					<Text
						tag={'p'}
						text={
							'Danke, dass Sie an dieser Studie teilnehmen wollen! Zuerst wird Ihnen ein Fragebogen angezeigt. Füllen Sie diesen bitte aus. Danach werden Sie durch den interaktiven Prototypen geführt. Zum Schluss wird Ihnen noch einmal ein Fragebogen angezeigt. Bitte füllen Sie diesen ebenfalls aus.'
						}
					/>
					<Text
						tag={'p'}
						text={
							'Beachten Sie bitte, dass ein Teil dieser Studie audiovisuell stattfindet. Stellen Sie sicher, dass der Ton eingeschalten ist.'
						}
					/>
					<Text
						tag={'p'}
						text={
							'Die Studie muss in einem Stück durchgeführt werden. Die Studie dauert ca. 15 Minuten. Es kann zu keinem Zeitpunkt zu einer früheren Seite zurückgekehrt werden. Es besteht nur die Möglichkeit die Seite neu zu laden und am gleichen Punkt wiedereinzusteigen.'
						}
					/>
					<Text tag={'p'} text={'Bei Fragen nutzen Sie bitte den blauen Kontakt Button rechts oben.'} />
					<Text
						tag={'p'}
						text={
							'Es werden keine personenbezogenen Daten gespeichert. Ihnen wird eine zufällig generierte, eindeutige ID zugewiesen. Es werden die Daten der Fragebögen und die gemessene Zeit, welche Sie für die Aufgaben des interaktiven Prototypen benötigen, gespeichert und evaluiert. Sollten Sie damit nicht einverstanden sein, können Sie an dieser Studie leider nicht teilnehmen.'
						}
					/>
					<SemanticButton positive onClick={this.onDisclaimerDone.bind(this)}>
						Weiter
					</SemanticButton>
				</>
			</Modal>
		)
	}

	private renderPreQuestionnaireModal(): JSX.Element {
		const { loading } = this.state

		return (
			<Modal headerText="Fragebogen 1">
				<PreQuestionnaire loading={loading} onSubmit={this.onPreQuestionnaireSend.bind(this)} />
			</Modal>
		)
	}

	private renderErrorModalIfNotOnDesktop(): JSX.Element {
		return (
			<Modal headerText="Achtung">
				<>
					<Text
						tag={'p'}
						text={
							this.isLandscapePossible()
								? 'Drehen Sie bitte Ihr Gerät (nur falls das möglich ist - zum Beispiel bei einem Tablet oder großen Handy) und laden die Seite erneut, um an der Studie teilzunehmen.'
								: 'Versuchen Sie es auf einem Gerät mit einer Auflösung von mindestens 1200px in der Breite bitte erneut!'
						}
					/>
				</>
			</Modal>
		)
	}

	private renderStartModal(): JSX.Element {
		return (
			<Modal headerText="Einweisung">
				<>
					<Text
						tag={'p'}
						text={
							'Sie werden nun durch den zweiten Teil der Studie geführt. Ihnen werden nun vier Nachrichtenseiten angezeigt. Auf jeder Seite wird Ihnen zu Beginn eine Aufgabe gestellt. Bitte erfüllen Sie diese Aufgabe.'
						}
					/>
					<Text
						tag={'p'}
						text={
							'Nachdem Sie die Lösung gefunden haben, finden Sie am Ende der Seite (ganz nach unten scrollen) einen großen grünen Button. Drücken Sie diesen um die Information einzutragen.'
						}
					/>
					<SemanticButton positive onClick={this.onStart.bind(this)}>
						Start
					</SemanticButton>
				</>
			</Modal>
		)
	}

	private renderSiteStartModal(): JSX.Element {
		const { loading, probandNr, sequenceIdx } = this.state
		const data: ISequence | null = probandNr && (sequences.runs[probandNr][sequenceIdx] as ISequence)
		// @ts-ignore
		const instruction = data && data.instruction
		// @ts-ignore
		const isVideo = data && data.articleType && data.articleType === 'video'

		// @ts-ignore
		return (
			<Modal headerText="Aufgabe">
				<>
					<Text tag={'p'} text={instruction || ''} />
					{isVideo && (
						<Text
							tag={'p'}
							text={
								'Achtung, das Video startet automatisch und die Werbung kann nicht übersprungen werden. Das Video selbst darf pausiert oder wiederholt werden.'
							}
						/>
					)}
					<Text tag={'p'} text={'Sobald Sie die Information gefunden haben, tragen Sie diese bitte ein.'} />
					<SemanticButton loading={loading} disabled={loading} positive onClick={this.onSiteStart.bind(this)}>
						Weiter
					</SemanticButton>
				</>
			</Modal>
		)
	}

	private renderDynamicTemplate(): JSX.Element {
		const { tagLoaded, probandNr, sequenceIdx } = this.state
		const Tag = tagLoaded && this.dynamicTag
		const data: ISequence | null = probandNr && (sequences.runs[probandNr][sequenceIdx] as ISequence)
		// @ts-ignore
		const highAdAmount = data && data.highAdAmount
		// @ts-ignore
		const articleType = data && data.articleType

		return (
			// @ts-ignore
			<Tag onClick={this.onSiteDone.bind(this)} highAdAmount={highAdAmount} articleType={articleType} /> || (
				<Modal headerText="Es ist ein Fehler aufgetreten">
					<Text
						tag={'p'}
						text={
							'Es ist ein Fehler aufgetreten. Sie können leider nicht mehr an dieser Studie teilnehmen.'
						}
					/>
				</Modal>
			)
		)
	}

	private renderSiteDoneModal(): JSX.Element {
		const { loading, probandNr, sequenceIdx } = this.state
		const data: ISequence | null = probandNr && (sequences.runs[probandNr][sequenceIdx] as ISequence)
		// @ts-ignore
		const instruction = data && data.instruction

		return (
			<Modal headerText="Aufgabe fertigstellen">
				<>
					<Text tag={'p'} text={'Geben Sie bitte die gesuchte Information im Textfeld unterbei ein.'} />
					<Text tag={'p'} text={`Nachfolgend die gestellte Aufgabe. ${instruction}`} />
					<Ref innerRef={this.inputRef}>
						<Input fluid label="Gesuchte Information:" placeholder="Bitte hier eingeben..." />
					</Ref>
					<div style={{ margin: '2rem 1rem', textAlign: 'right' }}>
						<SemanticButton
							positive
							loading={loading}
							onClick={this.onSiteDoneSend.bind(this)}
							style={{ margin: '0 1rem' }}
						>
							Senden
						</SemanticButton>
					</div>
				</>
			</Modal>
		)
	}

	private renderCompletedModal(): JSX.Element {
		return (
			<Modal headerText="Vielen Dank">
				<>
					<Text
						tag={'p'}
						text={
							'Sie haben den 2. Teil der Studie erfolgreich beendet! Drücken Sie auf Weiter um den letzten Fragebogen auszufüllen.'
						}
					/>
					<SemanticButton positive onClick={this.onStudyCompletedFurther.bind(this)}>
						Weiter
					</SemanticButton>
				</>
			</Modal>
		)
	}

	private renderPostQuestionnaireModal(): JSX.Element {
		const { loading } = this.state

		return (
			<Modal headerText="Fragebogen 2">
				<PostQuestionnaire loading={loading} onSubmit={this.onPostQuestionnaireSubmit.bind(this)} />
			</Modal>
		)
	}

	private renderPostQuestionnaireDoneModal(): JSX.Element {
		return (
			<Modal headerText="Geschafft!">
				<Text
					tag={'p'}
					text={
						'Vielen Dank für Ihre Teilnahme an der Studie! Es wird im Zuge einer Bachelorarbeit erforscht, ob die angezeigte Menge an Werbung in einem Nachrichtenportal einen Einfluss auf die Informationsfindung hat. Sie können die Seite nun schließen.'
					}
				/>
			</Modal>
		)
	}
}

export default NewsPage
