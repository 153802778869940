import React from 'react'
import { Button, Form } from 'semantic-ui-react'
import Text from '../../atoms/Text/Text'

interface IPostQuestionnaireProps {
	onSubmit: (questionnaireData: IPostQuestionnaireState) => Promise<void>
	loading: boolean
}

export interface IPostQuestionnaireState {
	hours: string
	adBlock: string
	newsRegular: string
	aesthatics: string
	informationFinding: string
	adDistraction: string
	userExperience: string
	additional: string
	page: number
}

class PostQuestionnaire extends React.Component<IPostQuestionnaireProps, IPostQuestionnaireState> {
	public state = {
		hours: '',
		adBlock: '',
		newsRegular: '',
		aesthatics: '',
		informationFinding: '',
		adDistraction: '',
		userExperience: '',
		additional: '',
		page: 1
	}
	// @ts-ignore
	public handlePostQuestionnaireInputChange = (e, { name, value }): void =>
		// @ts-ignore
		this.setState(prevState => ({
			...prevState,
			[name]: value
		}))

	public switchToPage = (pageNumber: number): void => {
		this.setState(prevState => ({ ...prevState, page: pageNumber }))
	}

	public handlePostQuestionnaireSubmit = (): void => {
		const { onSubmit } = this.props
		const attributes = Object.assign({}, this.state)
		delete attributes.page

		onSubmit({ ...attributes })
	}

	public render() {
		const { page } = this.state
		return (page === 1 && this.renderPageOne()) || (page === 2 && this.renderPageTwo())
	}

	private renderPageOne(): JSX.Element {
		const { hours, newsRegular, adBlock, aesthatics } = this.state
		// @ts-ignore
		return (
			<Form onSubmit={() => this.switchToPage(2)}>
				<Form.Input
					fluid
					required
					label="Wie viele Stunden verbringen Sie durchschnittlich am Tag im Internet, um an Informationen zu gelangen?"
					placeholder="Stunden"
					type="number"
					name="hours"
					value={hours}
					// @ts-ignore
					onChange={this.handlePostQuestionnaireInputChange}
				/>
				<Form.Group grouped>
					<Form.Field required>
						<label>Haben Sie schonmal die Funktionalitat eines AdBlockers in Anspruch genommen?</label>
					</Form.Field>
					<Form.Radio
						name="adBlock"
						label="ja"
						value="yes"
						checked={adBlock === 'yes'}
						// @ts-ignore
						onChange={this.handlePostQuestionnaireInputChange}
					/>
					<Form.Radio
						name="adBlock"
						label="nein"
						value="no"
						checked={adBlock === 'no'}
						// @ts-ignore
						onChange={this.handlePostQuestionnaireInputChange}
					/>
				</Form.Group>
				<Form.Group grouped>
					<Form.Field required>
						<label>Lesen Sie regelmäßig Zeitschriften über das Internet? (1 = nie, 5 = regelmäßig)</label>
					</Form.Field>
					<Form.Group inline>
						<Form.Radio
							name="newsRegular"
							label="1"
							value="1"
							checked={newsRegular === '1'}
							// @ts-ignore
							onChange={this.handlePostQuestionnaireInputChange}
						/>
						<Form.Radio
							name="newsRegular"
							label="2"
							value="2"
							checked={newsRegular === '2'}
							// @ts-ignore
							onChange={this.handlePostQuestionnaireInputChange}
						/>
						<Form.Radio
							name="newsRegular"
							label="3"
							value="3"
							checked={newsRegular === '3'}
							// @ts-ignore
							onChange={this.handlePostQuestionnaireInputChange}
						/>
						<Form.Radio
							name="newsRegular"
							label="4"
							value="4"
							checked={newsRegular === '4'}
							// @ts-ignore
							onChange={this.handlePostQuestionnaireInputChange}
						/>
						<Form.Radio
							name="newsRegular"
							label="5"
							value="5"
							checked={newsRegular === '5'}
							// @ts-ignore
							onChange={this.handlePostQuestionnaireInputChange}
						/>
					</Form.Group>
				</Form.Group>
				<Form.Group grouped>
					<Form.Field required>
						<label>Fanden Sie die Ihnen gezeigte Webseite ansprechend?</label>
					</Form.Field>
					<Form.Radio
						name="aesthatics"
						label="ja"
						value="yes"
						checked={aesthatics === 'yes'}
						// @ts-ignore
						onChange={this.handlePostQuestionnaireInputChange}
					/>
					<Form.Radio
						name="aesthatics"
						label="nein"
						value="no"
						checked={aesthatics === 'no'}
						// @ts-ignore
						onChange={this.handlePostQuestionnaireInputChange}
					/>
				</Form.Group>
				<Button>Nächste Seite</Button>
			</Form>
		)
	}

	private renderPageTwo(): JSX.Element {
		const { loading } = this.props
		const { informationFinding, adDistraction, userExperience } = this.state
		return (
			<Form onSubmit={this.handlePostQuestionnaireSubmit.bind(this)}>
				<Form.Group grouped>
					<Form.Field required>
						<label>
							Wie schwer war es für Sie die gewünschte Information zu finden? (1 = sehr leicht, 5 = sehr
							schwer)
						</label>
					</Form.Field>
					<Form.Group inline>
						<Form.Radio
							name="informationFinding"
							label="1"
							value="1"
							checked={informationFinding === '1'}
							// @ts-ignore
							onChange={this.handlePostQuestionnaireInputChange}
						/>
						<Form.Radio
							name="informationFinding"
							label="2"
							value="2"
							checked={informationFinding === '2'}
							// @ts-ignore
							onChange={this.handlePostQuestionnaireInputChange}
						/>
						<Form.Radio
							name="informationFinding"
							label="3"
							value="3"
							checked={informationFinding === '3'}
							// @ts-ignore
							onChange={this.handlePostQuestionnaireInputChange}
						/>
						<Form.Radio
							name="informationFinding"
							label="4"
							value="4"
							checked={informationFinding === '4'}
							// @ts-ignore
							onChange={this.handlePostQuestionnaireInputChange}
						/>
						<Form.Radio
							name="informationFinding"
							label="5"
							value="5"
							checked={informationFinding === '5'}
							// @ts-ignore
							onChange={this.handlePostQuestionnaireInputChange}
						/>
					</Form.Group>
				</Form.Group>
				<Form.Group grouped>
					<Form.Field required>
						<label>
							Wie stark haben Sie sich durch die Werbung in der Informationsfindung abgelenkt gefühlt? (1
							= sehr wenig, 5 = sehr stark)
						</label>
					</Form.Field>
					<Form.Group inline>
						<Form.Radio
							name="adDistraction"
							label="1"
							value="1"
							checked={adDistraction === '1'}
							// @ts-ignore
							onChange={this.handlePostQuestionnaireInputChange}
						/>
						<Form.Radio
							name="adDistraction"
							label="2"
							value="2"
							checked={adDistraction === '2'}
							// @ts-ignore
							onChange={this.handlePostQuestionnaireInputChange}
						/>
						<Form.Radio
							name="adDistraction"
							label="3"
							value="3"
							checked={adDistraction === '3'}
							// @ts-ignore
							onChange={this.handlePostQuestionnaireInputChange}
						/>
						<Form.Radio
							name="adDistraction"
							label="4"
							value="4"
							checked={adDistraction === '4'}
							// @ts-ignore
							onChange={this.handlePostQuestionnaireInputChange}
						/>
						<Form.Radio
							name="adDistraction"
							label="5"
							value="5"
							checked={adDistraction === '5'}
							// @ts-ignore
							onChange={this.handlePostQuestionnaireInputChange}
						/>
					</Form.Group>
				</Form.Group>
				<Form.Group grouped>
					<Form.Field required>
						<label>
							Haben Sie sich durch die Werbung in der Benutzung der Webseite negativ beeinträchtigt
							gefühlt?
						</label>
					</Form.Field>
					<Form.Radio
						name="userExperience"
						label="ja"
						value="yes"
						checked={userExperience === 'yes'}
						// @ts-ignore
						onChange={this.handlePostQuestionnaireInputChange}
					/>
					<Form.Radio
						name="userExperience"
						label="nein"
						value="no"
						checked={userExperience === 'no'}
						// @ts-ignore
						onChange={this.handlePostQuestionnaireInputChange}
					/>
				</Form.Group>
				<Form.TextArea
					rows={5}
					label="Anmerkungen"
					placeholder="Sollte Ihnen irgendetwas aufgefallen sein, schreiben Sie es bitte in dieses Feld..."
					name="additional"
					// @ts-ignore
					onChange={this.handlePostQuestionnaireInputChange}
				/>
				<Form.Group inline>
					<Button onClick={() => this.switchToPage(1)}>Zurück</Button>
					<Form.Button positive loading={loading}>
						Senden
					</Form.Button>
				</Form.Group>
			</Form>
		)
	}
}

export default PostQuestionnaire
