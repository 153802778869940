/* global process */
import React from 'react'
import Image from '../../atoms/Image/Image'
import { IBasePattern } from '../../utils/BasePatterns'
import generateClasslist from '../../utils/generateClasslist'

import './ad.scss'
import { Advertisement } from 'semantic-ui-react'

export enum Modifier {
	Banner = 'banner',
	Leaderboard = 'leaderboard',
	Skyscraper = 'skyscraper',
	LargeRectangle = 'large rectangle',
	MediumRectangle = 'medium rectangle',
	VerticalRectangle = 'vertical rectangle'
}

export interface IAdProps extends IBasePattern {
	screenType: Modifier
	imageProps: { alt: string }
}

const BASE_CLASS = 'm-ad'

export default class Ad extends React.Component<IAdProps> {
	public render(): JSX.Element {
		const {
			modifiers,
			screenType,
			imageProps: { alt }
		} = this.props

		let adType

		if (screenType === Modifier.Banner) {
			adType = Math.round(Math.random())
		} else {
			adType = Math.floor(Math.random() * 5)
		}
		return (
			<div className={generateClasslist({ baseClass: BASE_CLASS, modifiers })}>
				<Advertisement unit={screenType}>
					<Image
						src={`ads/${screenType.substr(screenType.indexOf(' ') + 1)}/ad${adType}.png`}
						alt={alt}
						modifiers={[screenType, 'ad']}
					/>
				</Advertisement>
			</div>
		)
	}
}
