/* global window, document */

export const checkIfDesktop = (): boolean => {
	const getVpFromCss = getComputedStyle((document && document.documentElement) || new HTMLElement()).getPropertyValue(
		'--vp-width'
	)
	return getVpFromCss.trim() === '1200px'
}

// implement check if landscape is possible

export const checkIfLandscapeIsPossible = (): boolean => {
	const vpHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
	return window.matchMedia('(orientation: portrait)').matches && vpHeight >= 1200
}

export const getUserAgent = (): string => window && window.navigator.userAgent

export const getVpSizes = (): object => {
	const vpHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
	const vpWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
	return { vpHeight, vpWidth }
}
