import React from 'react'
import { IBasePattern } from '../../utils/BasePatterns'
import generateClasslist from '../../utils/generateClasslist'

import './text.scss'
import Ad, { Modifier } from '../../molecules/Ad/Ad'

export interface ITextProps extends IBasePattern {
	tag: 'p' | 'span'
	text: string
	ad?: boolean
}

const BASE_CLASS = 'a-text'

const Text: React.FunctionComponent<ITextProps> = ({ tag, text, ad, modifiers }: ITextProps): JSX.Element => {
	const Tag = tag
	return (
		<div className={generateClasslist({ baseClass: BASE_CLASS, modifiers })}>
			<Tag className={`a-text__text ${ad && 'a-text__text--wrap-around'}`}>{text}</Tag>
			{ad && (
				<Ad
					screenType={Modifier.VerticalRectangle}
					imageProps={{ src: '', alt: '' }}
					modifiers={['vertical-rectangle']}
				/>
			)}
		</div>
	)
}

export default Text
